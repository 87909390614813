<template>
  <div>
    <abouts-us></abouts-us>
  </div>
</template>

<script>
import AboutsUs from '../components/AboutsUs.vue'

export default {
  name: "About",
  components: { AboutsUs },

}
</script>
